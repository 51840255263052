<template>
  <div class="zee-container">
    <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <zee-screen-title :title="'Select Promo Duration'" :route="'/solutions/zeetv/competitor-Launch-targets'" />

    <div class="zee-card-background">
      <zee-tabs :title="'Select Promo Duration'" :icon="'angle-right'">
        <zee-three-dropdown @getSelectedHeader="getSelectedOptions" :defaultValue="defaultDropdownValues" />
        <zee-button class="d-none" />
      </zee-tabs>
      <!-- <zee-input
        class="ml-3"
        :placeholder="'Custom Duration'"
        @onChange="handleACDsInput"
      /> -->
      <div v-if="selectedOptions.length !== 0" class="zee-acd-table-wrapper">
        <div class="ml-3 mt-2 fs-4 font-weight-bold text-secondary">
          Enter values in %
        </div>
        <div class="ml-3 mt-2 fs-4 font-weight-bold text-secondary">
          sum of every weeks should be 100%
        </div>
        <div class="zee-acd-table-wrapper-inner">
          <zee-table :tableHeaderData="tableHeaderData">
            <zee-custom-input-table @onInput="handleCustomInput" :customInputData="customOptions" />
          </zee-table>
          <!-- <zee-table :tableHeaderData="tableSummaryData" /> -->
          <!-- <div class="ml-5 fs-5 mt-3 font-weight-bold" v-if="!validatePromoDuration">* Total Promo duration must be even</div> -->
        </div>
      </div>
      <div v-if="selectedOptions.length !== 0" class="zee-acd-percentage-table-wrapper">
        <h4 class="text-secondary font-weight-bold mt-5 ml-3">PT Percentage ( Percentage of GRP's to Be mentioned.)</h4>
        <div class="zee-acd-percentage-table-wrapper-inner">
          <zee-custom-input-table @onInput="handleCustomPercentageInput" :input="true"
            :customInputData="customOptions1" />
        </div>
        <div class="ml-5 fs-5 font-weight-bold">Last five shows average 50% - 65%</div>
        <div class="ml-5 fs-5 mt-3 font-weight-bold">* Based on model recommendation</div>
      </div>

      <div v-if="selectedOptions.length !== 0" class="zee-program-save-button">
        <zee-button @onClick="handleClick" title="Next" :isDisable="isDisable"></zee-button>
      </div>
    </div>

    <select-acs-modal class="d-none" :modalShow="openSelectACDModal" @closeModal="openSelectACDModal = false"
      @handleNextReq="handleClick" />
  </div>
</template>

<script>
import {
  // MultiDropdown,
  Tabs,
  Button,
  Table,
  // Input,
  CustomInputTable,
  ScreenTitle,
  ThreeDropDown,
  SelectACSModal,
} from "../components";
import Toast from "@/components/Toast/Toast.vue";

import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();

export default {
  name: "SelectACD",
  components: {
    // "zee-multi-dropdown": MultiDropdown,
    "zee-tabs": Tabs,
    "zee-button": Button,
    "zee-table": Table,
    // "zee-input": Input,
    "zee-custom-input-table": CustomInputTable,
    "zee-toast": Toast,
    "zee-screen-title": ScreenTitle,
    "zee-three-dropdown": ThreeDropDown,
    "select-acs-modal": SelectACSModal,
  },
  data() {
    return {
      isPtPercentageValid: false,
      isDisable: false,
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      openSelectACDModal: false,
      customOptions: [
        // {
        //   columns: [
        //     { id: [null, null], name: "05 sec" },
        //     { id: [0, 1], name: "", value: "" },
        //     { id: [0, 2], name: "", value: "" },
        //     { id: [0, 3], name: "", value: "" },
        //     { id: [0, 4], name: "", value: "" },
        //   ],
        // },
        // {
        //   columns: [
        //     { id: [null, null], name: "10 sec" },
        //     { id: [1, 1], name: "", value: "" },
        //     { id: [1, 2], name: "", value: "" },
        //     { id: [1, 3], name: "", value: "" },
        //     { id: [1, 4], name: "", value: "" },
        //   ],
        // },
        // {
        //   columns: [
        //     { id: [null, null], name: "15 sec" },
        //     { id: [2, 1], name: "", value: "" },
        //     { id: [2, 2], name: "", value: "" },
        //     { id: [2, 3], name: "", value: "" },
        //     { id: [2, 4], name: "", value: "" },
        //   ],
        // },
        // {
        //   columns: [
        //     { id: [null, null], name: "Total" },
        //     { id: [3, 1], name: "100%", value: "", total: true },
        //     { id: [3, 2], name: "80%", value: "", total: false },
        //     { id: [3, 3], name: "50%", value: "", total: false },
        //     { id: [3, 4], name: "30%", value: "", total: false },
        //   ],
        // },
      ],
      customOptions1: [
        {
          columns: [
            { id: [null, null], name: "PT %" },
            { id: [0, 1], name: "", value: "" },
            // { id: [0, 2], name: "", value: "" },
            // { id: [0, 3], name: "", value: "" },
            // { id: [0, 4], name: "", value: "" },
          ],
        },
      ],
      summaryOptionsSelected1: [
        {
          id: "05",
          name: "05 sec",
          value: false,
          $isDisabled: false,
          key: "05 sec",
        },
        {
          id: "10",
          name: "10 sec",
          value: false,
          $isDisabled: false,
          key: "10 sec",
        },
        {
          id: "15",

          name: "15 sec",
          value: false,
          $isDisabled: false,
          key: "15 sec",
        },
      ],
      summaryOptionsSelected2: [
        {
          id: "20",

          name: "20 sec",
          value: false,
          $isDisabled: false,
          key: "20 sec",
        },
        {
          id: "25",

          name: "25 sec",
          value: false,
          $isDisabled: false,
          key: "25 sec",
        },
        {
          id: "30",
          name: "30 sec",
          value: false,
          $isDisabled: false,
          key: "30 sec",
        },
      ],
      summaryOptionsSelected3: [
        {
          id: "40",
          name: "40 sec",
          value: false,
          $isDisabled: false,
          key: "40 sec",
        },
        {
          id: "50",

          name: "50 sec",
          value: false,
          $isDisabled: false,
          key: "50 sec",
        },
        {
          id: "60",
          name: "60 sec",
          value: false,
          $isDisabled: false,
          key: "60 sec",
        },
      ],
      tableBodyData: [
        {
          columns: [
            { name: "05 sec" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
        {
          columns: [
            { name: "10 sec" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
        {
          columns: [
            { name: "15 sec" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
        {
          columns: [
            { name: "Total" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
      ],
      tableHeaderData: [
        {
          columns: [
            // { name: "Duration" },
            // { name: "week-4" },
            // { name: "week-3" },
            // { name: "week-2" },
            // { name: "week-1" },
          ],
        },
      ],
      tableSummaryData: [
        {
          columns: [],
        },
      ],
      tableBodyData1: [
        {
          columns: [
            { name: "PT %" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
            { name: "10%" },
          ],
        },
      ],
      selectedWeeks: "",
      selectedOptions: [],
      customInput: [],
      secondages: {},
      weeklyData: [],
      ptPercentageInput: "",
      weeklySecondages: [],
      totalWeeklySecondages: [],
      acdsInput: "",
      routeData: "",
      selectedOptionsCounter: 0,
      selectedAcdsSeconds: [],
      defaultDropdownValues: []
    };
  },
  computed: {
    validatePromoDuration() {
      let promoSum = 0;
      for (let i = 0; i < this.selectedOptions.length; i++) {
        promoSum += parseInt(this.selectedOptions[i].key.replace(" sec", ""));
      }
      return promoSum % 10 == 0;
    },
  },
  methods: {
    getSelectedOptions(e) {
      console.log(e)
      // console.log(this.$route.params.data)
      // console.log("selected options event",e)
      console.log(this.selectedOptionsCounter++);
      if (this.routeData !== "program-plan" && this.selectedOptionsCounter > 3) {
        console.log(this.routeData);
        this.selectedOptions = e;
        // console.log("selected",this.selectedOptions)
        // let acds = this.selectedOptions.map((y) => {
        //   return y.key.replace(" sec", "");
        // });
        let weeks = +sessionStorage.getItem("weeks");
        // console.log("in the getselectedoptions")
        // console.log(e)
        // if(e.length === 0 && this.selectedOptionsTemp.length !== 0){

        // }
        e === undefined
          ? this.handleTableCreation(weeks, 0)
          : this.handleTableCreation(weeks, e.length);
      } else {
        console.log(this.routeData);
        if (this.selectedOptionsCounter === 3) {
          this.routeData = "";
        }
        return;
      }
    },
    handleTableCreation(weeks, acds) {
      this.tableHeaderData[0].columns = [];
      this.tableSummaryData[0].columns = [];
      this.customOptions = [];
      // console.log("in handle table creation")
      for (var i = weeks; i >= 0; i--) {
        this.tableSummaryData[0].columns.push({ name: "" });
        this.tableHeaderData[0].columns.push({ name: `week ${i + 1}` });
      }

      this.tableHeaderData[0].columns[0].name = "Options";
      this.tableSummaryData[0].columns[0].name = "Total";

      //for customTable
      for (var j = 0; j < acds; j++) {
        this.customOptions.push({ columns: [] });
        // this.customOptions.push({columns:[{name: "Total"}]})
      }

      for (var k = 0; k < acds; k++) {
        for (var l = 0; l < weeks + 1; l++) {
          this.customOptions[k].columns.push({ name: "", id: [k, l], value: "" });
        }
        // this.customOptions.push({ columns: [] });
      }

      for (var m = 0; m < acds; m++) {
        this.customOptions[m].columns[0].name = this.selectedOptions[m].key;
      }
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },

    handleClick() {
      this.weeklyData = [];
      console.log("in handle click", this.customOptions)
      for (var j = 1; j < this.customOptions[0].columns.length; j++) {
        this.weeklySecondages = [];
        for (var k = 0; k < this.customOptions.length; k++) {
          this.weeklySecondages.push(+this.customOptions[k].columns[j].value.replace("%", ""));
        }
        this.weeklyData.push(this.weeklySecondages);
      }
      // console.log(this.weeklyData);

      this.calculateSecondages();

      // let weeks = +sessionStorage.getItem("weeks");
      let prevData = JSON.parse(localStorage.getItem("programDetails"));

      // console.log("this.totalWeeklySecondages", this.totalWeeklySecondages);

      // console.log(this.acdsInput);

      // if (old_key !== new_key) {
      //   Object.defineProperty(o, new_key, Object.getOwnPropertyDescriptor(o, old_key));
      //   delete o[old_key];
      // }

      if (this.acdsInput !== "" && this.acdsInput !== undefined) {
        for (var h = 0; h < this.totalWeeklySecondages.length; h++) {
          if (Object.keys(this.totalWeeklySecondages[h]).toString() === "undefined") {
            // this.totalWeeklySecondages[h]["90".toString()] = this.totalWeeklySecondages[
            //   h
            // ]["undefined"];
            // delete this.totalWeeklySecondages[h]["undefined"];
            if ("undefined" !== this.acdsInput) {
              Object.defineProperty(
                this.totalWeeklySecondages[h],
                " " + this.acdsInput,
                Object.getOwnPropertyDescriptor(
                  this.totalWeeklySecondages[h],
                  "undefined"
                )
              );
              delete this.totalWeeklySecondages[h]["undefined"];
            }
          }
        }
      }
      sessionStorage.setItem("acds", JSON.stringify(this.totalWeeklySecondages));

      // console.log(this.totalWeeklySecondages);

      prevData[0].secondages = this.totalWeeklySecondages;

      prevData[0].programPTPercentage = this.ptPercentageInput;

      // prevData[0].planName = prevData[0].programName;

      prevData[0].userEmail = sessionStorage.getItem("clientId");

      prevData[0].creationDate = this.formatDate(new Date());

      localStorage.setItem("programDetails", JSON.stringify(prevData));
      sessionStorage.setItem("customOptions", JSON.stringify(this.customOptions));

      let data = JSON.parse(localStorage.getItem("programDetails"));

      zeeSolutions
        .postUserInput(...data)
        .then((res) => {
          sessionStorage.setItem("run_id", res.runid);
          sessionStorage.setItem("show_add_program_screen", false);
        })
        .then(() => {
          this.$router.push("/solutions/zeetv/program-plans");
        })
        .catch(() => {
          this.toastData = {
            show: true,
            type: "edit",
            message: "Something went wrong, please check fill all the fields",
          };
          // this.$router.push("/solutions/zeetv/program-plans");

          // this.$router.push("/solutions/zeetv/program-plans");
        });

      // console.log(this.customOptions)

      // this.$router.push("/solutions/zeetv/program-plans");
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    calculateSecondages() {
      let temp = [];
      //  let temp1 = {};
      let keyname = [];
      // console.log("this is selectedOptions",this.selectedOptions)
      for (var j = 0; j < this.selectedOptions.length; j++) {
        keyname.push(this.selectedOptions[j].key.replace("sec", ""));
        /* console.log(keyname)
  temp1[keyname] = "" */
      }
      // console.log(weeklyData)
      for (var i = 0; i < this.weeklyData.length; i++) {
        var rows = this.weeklyData[i];
        var columns = keyname;
        var result = rows.reduce(function (result, field, index) {
          result[columns[index]] = field / 100;
          return result;
        }, {});
        temp.push(result);
      }
      // console.log(temp);

      this.totalWeeklySecondages = temp;
      // console.log(this.totalWeeklySecondages)
      // this.weeklySecondages = [];
      // for (var i = 1; i <= this.customOptions.length; i++) {
      //   this.weeklyData = [];
      //   for (var j = 0; j < this.customOptions.length; j++) {
      //     this.weeklyData.push(this.customOptions[j].columns[i].value);
      //   }
      //   this.weeklySecondages.push(this.weeklyData);
      // }
      // console.log(this.weeklySecondages)
      // let data = this.weeklySecondages.filter((elem) => elem!="")
      // console.log(data)
      // let selectedOptions = this.selectedOptions;
      // let customInput = this.customInput;
      // let temp = {};
      // for (var i = 0; i < selectedOptions.length; i++) {
      //   let keyname = selectedOptions[i].key.replace("sec", "");
      //   temp[keyname] = customInput[i] / 100;
      // }
      // this.secondages = temp;
    },
    handleCustomInput(e) {
      let id = e.id;
      this.customOptions[id[0]].columns[id[1]].value = e.event.target.value;

      // console.log(this.customOptions);

      let output = [];

      for (var i = 0; i < this.customOptions.length; i++) {
        output.push(+this.customOptions[i].columns[id[1]].value.replace("%", ""));
      }

      this.customInput = output;

      let temp = [];

      for (var k = 0; k < this.customOptions.length; k++) {
        let y = this.customOptions[k].columns
          .map((x) => {
            return x;
          })
          .filter((j) => j.value === "");
        temp = y;
      }

      console.log("temp", temp);

      // temp.length > 1 ? (this.isDisable = true) : (this.isDisable = false);

      let sum = output.reduce((accumulator, currentValue) => accumulator + currentValue);

      sum != 100
        ? ((this.toastData = {
          show: true,
          type: "edit",
          message: "sum of every weeks should be 100%",
        }),
          (this.isDisable = true))
        : ((this.isDisable = false),
          (this.toastData = {
            show: false,
            type: "edit",
            message: "",
          }));
    },
    handleCustomPercentageInput(e) {
      // let id = e.id;
      // this.customOptions1[id[0]].columns[id[1]].value = e.event.target.value;
      this.ptPercentageInput = +e.event.target.value;

      e.event.target.value < 50 || e.event.target.value > 65
        ? ((this.toastData = {
          show: true,
          type: "edit",
          message: "Recommended PT Percentage range is between 50 - 65",
        }),
          (this.isDisable = true))
        : ((this.toastData = {
          show: false,
          type: "edit",
          message: "",
        }),
          (this.isDisable = false));
    },
    getData() {
      let weeks = sessionStorage.getItem("weeks");
      this.selectedWeeks = weeks + " " + "Weeks";

      // this.handleTableCreation(weeks);
    },
    retriveData() {
      let customOptions = JSON.parse(sessionStorage.getItem("customOptions"));
      let prevData = JSON.parse(localStorage.getItem("programDetails"));
      this.ptPercentageInput = prevData[0].programPTPercentage;
      this.customOptions1[0].columns[1].value = prevData[0].programPTPercentage;
      console.log(this.ptPercentageInput);
      customOptions === null
        ? (this.toastData = {
          show: true,
          message: "please enter promo duration",
          type: "edit",
        })
        : (this.customOptions = customOptions);
      for (let i = 0; i < customOptions.length; i++) {
        let name = customOptions[i].columns[0].name;
        let tempObject = { key: `${name}`, label: `${name}` };
        this.selectedOptions.push(tempObject);
      }
      console.log("this is temp selected options", this.selectedOptionsTemp);
      let weeks = +sessionStorage.getItem("weeks");
      this.tableHeaderData[0].columns = [];
      this.tableSummaryData[0].columns = [];
      for (var i = weeks; i >= 0; i--) {
        this.tableSummaryData[0].columns.push({ name: "" });
        this.tableHeaderData[0].columns.push({ name: `week ${i + 1}` });
      }

      this.tableHeaderData[0].columns[0].name = "Options";
    },
  },
  created() {
    //temp
    // console.log("params: ", this.$route.params.data)
    this.toastData = {
      show: true,
      message: "Please enter promo duration",
      type: "edit",
    };
    this.retriveData();
    this.getData();


    if (this.$route.params.data === "Launch Targets") {
      console.log("inside launch targets");
      this.getSelectedOptions();
    }
    if (this.$route.params.data === "program-plan") {
      this.routeData = this.$route.params.data;
      const secondages = localStorage.getItem("programDetails") || null;
      if(secondages) {
        let second = JSON.parse(secondages)[0].secondages;
        for(const index in second[0]) {
            this.defaultDropdownValues.push(index);
        }
      }
    }
    this.acdInputValue =
      JSON.parse(localStorage.getItem("programDetails"))[0].planName || "";
  },
};
</script>

<style>
.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  height: 100%;
}

.zee-card-background {
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  margin-bottom: 54px;
  border-radius: 15px;
}

.zee-acd-table-wrapper {
  margin-top: 28px;
  padding: 1rem;
  margin: 3rem;
  background-color: #f3f3f3;
  border-radius: 20px;
}

.zee-acd-table-wrapper-inner {
  width: 50%;
}

.zee-acd-percentage-table-wrapper {
  background-color: #f3f3f3;
  border-radius: 20px;
  padding: 1rem;
  margin: 3rem;
  margin-top: 28px;
}

.zee-acd-percentage-table-wrapper-inner {
  display: flex;
  width: 100%;
}

.zee-program-save-button {
  display: flex;
  justify-content: flex-end;
}

.zee-custom-select-table-header-wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 18px;
  margin-left: 30px;
  margin-right: 15px;
}
</style>
